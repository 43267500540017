/* eslint-disable @next/next/no-img-element */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './Loading.module.sass';

const Loading = ({ isFullscreen, size }) => {
  const classes = [isFullscreen ? styles.container : '', styles[size]].join(
    ' '
  );

  return (
    <div className={classes}>
      <img alt="Cake" height="300" src="/cake-logo.svg" width="300" />
      <img alt="Loading..." height="40" src="/icons/loading.svg" width="40" />
    </div>
  );
};

export default Loading;

Loading.defaultProps = {
  isFullscreen: true,
  size: 'large',
};

Loading.propTypes = {
  isFullscreen: PropTypes.bool,
  size: PropTypes.string,
};
