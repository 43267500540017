import React from 'react';
import PropTypes from 'prop-types';

import Container from '@/components/Container/Container';
import Header from '@/components/Header/Header';

import styles from './Post.module.sass';

const Post = ({ content, featuredImage, title }) => (
  <>
    <Header />
    <Container>
      <article className={styles.post}>
        <h1>{title}</h1>
        {featuredImage?.sourceUrl && (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            alt={title}
            className={styles.imageContainer}
            src={featuredImage?.sourceUrl}
          />
        )}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </article>
    </Container>
  </>
);

export default Post;

Post.defaultProps = {
  notFound: false,
};

Post.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    })
  ),
  content: PropTypes.string,
  featuredImage: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
  }),
  notFound: PropTypes.bool,
  title: PropTypes.string,
};
