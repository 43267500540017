import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import client from '@/apollo-client';
import Loading from '@/components/Loading/Loading';
import Post from '@/components/Post/Post';
import { getPostQuery } from '@/graphql/queries';
import styles from '@/styles/Home.module.sass';

export default function PostPage({ ...post }) {
  const { isFallback } = useRouter();
  const title = post?.title;

  return (
    <div className={styles.container}>
      <Head>
        <title>{isFallback ? 'loading...' : title} | cake-mag.com</title>
        {!isFallback && (
          <>
            <meta
              key="ogImage"
              property="og:image"
              content={post?.featuredImage?.sourceUrl}
            />
            <meta
              key="ogTitle"
              property="og:title"
              content={`${title} | cake-mag.com`}
            />
          </>
        )}
      </Head>
      {isFallback ? <Loading /> : <Post {...post} />}
    </div>
  );
}

export async function getStaticProps({ params }) {
  const { query, variables } = getPostQuery({ slug: params?.slug });
  const { data } = await client.query({ query, variables });

  if (!data || !data?.postBy) {
    return {
      notFound: true,
    };
  }
  const { categories, content, featuredImage, title } = data?.postBy;

  return {
    props: {
      categories: categories?.nodes,
      content,
      featuredImage: featuredImage?.node ?? null,
      title,
    },
    revalidate: 30 * 60,
  };
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true,
  };
}

PostPage.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    })
  ),
  content: PropTypes.string,
  featuredImage: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
  }),
  title: PropTypes.string,
};
